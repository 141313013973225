/* src/PrivacyPolicy.css */
.privacy-policy-container {
    min-height: 100vh;
    background-color: #f7f7f7;
    padding: 20px;
    overflow-y: auto;
}

/* src/PrivacyPolicy.css */
.privacy-policy-content {
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: left; /* Add this line */
}


.privacy-policy-title {
    font-size: 2em;
    margin-bottom: 10px;
    text-align: center;
    color: #333;
}

.privacy-policy-date {
    font-size: 1em;
    margin-bottom: 20px;
    text-align: center;
    color: #777;
}

.privacy-policy-text {
    font-size: 1em;
    margin-bottom: 20px;
    line-height: 1.6;
    color: #555;
}

.privacy-policy-section {
    margin-bottom: 20px;
}

.privacy-policy-subtitle {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #333;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
    color: #555;
}

li {
    margin-bottom: 10px;
}
