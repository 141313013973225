@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .fadeInEffect {
    animation: fadeInUp 0.5s ease-out both;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .videoFadeIn {
    animation: fadeIn 0.5s ease-out forwards; /* Adjust duration as needed */
  }
  
  .videoFadeOut {
    animation: fadeOut 1s ease-out forwards; /* Adjust duration as needed */
  }
  